// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bildquellenverzeichnis-jsx": () => import("./../src/pages/bildquellenverzeichnis.jsx" /* webpackChunkName: "component---src-pages-bildquellenverzeichnis-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-jsx": () => import("./../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-playlists-jsx": () => import("./../src/pages/playlists.jsx" /* webpackChunkName: "component---src-pages-playlists-jsx" */),
  "component---src-pages-whagwaan-jsx": () => import("./../src/pages/whagwaan.jsx" /* webpackChunkName: "component---src-pages-whagwaan-jsx" */)
}

